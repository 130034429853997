<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/roomInfo">房间管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleAdd(null)"
      >新增园区</el-button>
      <!-- <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除选中项</el-button> -->
    </el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      row-key="id"
      stripe
      border
      lazy
      :load="load"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column prop="name" sort-by="name_" label="名称" sortable="custom" width="180"></el-table-column>
      <el-table-column prop="type" sort-by="type_" label="类型" sortable="custom" width="180">
        <template slot-scope="scope">
          <p v-if="scope.row.type=='1'">园区</p>
          <p v-if="scope.row.type=='2'">楼栋</p>
          <p v-if="scope.row.type=='3'">房号</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="useTypeN"
        sort-by="use_type_n"
        label="用电类型"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column prop="sortNo" sort-by="sort_no" label="排序号" sortable="custom" width="180"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{row}">
          <el-button size="mini" type="primary" @click="handleAdd(row)" v-show="row.type!=3">
              {{row.type==1 ? "添加楼栋" : "添加房间"}}
          </el-button>
          <el-button size="mini" type="warning" @click="handleEdit(row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <roomInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :parentId="selectedRecord.id"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></roomInfo-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import RoomInfoDetail from "./roomInfo-detail";
import roomInfoApi from "@/api/base/roomInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "BaseRoomInfoList",
  data() {
    var self = this;

    return {
      queryModel: {
        id: "",
        name: "",
        number: "",
        type: "1",
        sortNo: "",
        parentId: "",
        createBy: "",
        createTime: "",
        updateBy: "",
        updateTime: "",
        delFlag: ""
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 100,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      operation:'',
      businessKey: "",
      selectedRecord : null
      //loadNodeMap: new Map()
    };
  },
  methods: {
    load(row, treeNode, resolve) {
      if (row != null) {
        const id = row.id;
        var formData = new FormData();
        
        formData.append("parentId", id);
        // const pid = tree.id;
        // this.loadNodeMap.set(pid, { tree, treeNode, resolve });
        roomInfoApi
          .pageList(formData)
          .then(function(response) {
            self.loading = false;
            var jsonData = response.data.data;

            row.children = jsonData.data;

            resolve(jsonData.data);
          })
          .catch(error => {
            self.loading = false;
            // self.$message.error(error + "");
          });
      }
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("name", self.queryModel.name);
      formData.append("type", self.queryModel.type);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      roomInfoApi
        .pageList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.changePage(this.pageIndex);
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd(record) {
      this.modalTitle = "新增";
      this.operation = "add";
      this.businessKey = "";

      if(record!=null){
        this.selectedRecord = record;
      }
      else{
        this.selectedRecord = {};
      }

      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.operation = "edit";
      this.businessKey = record.id;
      this.selectedRecord = record;
      this.showModal = true;
    },
    loopDelete(list,id){
      var rs = false;

      for(var i=0;i<list.length;i++){
        if(list[i].id == id){
          list.splice(i,1);
          rs = true;
          break;
        }

        if(list[i].children!=null){
          rs = this.loopDelete(list[i].children,id);

          if(rs){
            break;
          }
        }
      }

      return rs;
    },    
    loopFind(list,id){
      var rs = null;

      for(var i=0;i<list.length;i++){
        if(list[i].id == id){
          rs = list[i];
          break;
        }

        if(list[i].children!=null){
          rs = this.loopFind(list[i].children,id);

          if(rs!=null){
            break;
          }
        }
      }

      return rs;
    },
    handleDelete(record) {
      var self = this;

      self.$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          roomInfoApi.remove(record.id).then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              var rs = self.loopDelete(self.tableData,record.id);

              self.$message({
                type: "success",
                message: "删除成功!"
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        roomInfoApi.batchRemove(idList).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    onDetailModalClose(retObj) {
      //保存成功后回调
      this.showModal = false;

      if (retObj.result) {
        var retData = retObj.data;

        if(this.operation=="edit"){
          this.selectedRecord.name = retData.name;
          this.selectedRecord.number = retData.number;
          this.selectedRecord.type = retData.type;
          this.selectedRecord.useType = retData.useType;
          this.selectedRecord.sortNo = retData.sortNo;

          if(this.selectedRecord.parentId != retData.parentId){
            this.loopDelete(this.tableData,this.selectedRecord.id);

            var parent = this.loopFind(this.tableData,retData.parentId);

            if(parent!=null){
              if(parent.children==null){
                parent.children = []
              }

              parent.children.push(retData);
            }
          }
        }
        else if(this.operation=="add"){
          if(this.selectedRecord.id==null){
            console.log("tableData.push");
            this.tableData.push(retData);
          }
          else{
            if(this.selectedRecord.children==null){
              this.selectedRecord.children = [];
            }

            this.selectedRecord.hasChildren = true;
            this.selectedRecord.children.push(retData);
          }
        }
      }
    }
  },
  mounted: function() {
    this.changePage(1);
  },
  components: {
    "roomInfo-detail": RoomInfoDetail
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  padding: 10px;
  text-align: left;
}
</style>