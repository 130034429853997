<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'150px'">
        <el-form-item label="名称" prop="name">
          <el-input v-model="formModel.name" placeholder="请输入名称" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="类型:" prop="type">
          <el-select v-model="formModel.type" @change="selectType" disabled="disabled">
            <el-option label="园区" value="1"></el-option>
            <el-option label="楼栋" value="2"></el-option>
            <el-option label="房号" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用电类型" prop="useType">
          <el-select
            v-model="formModel.useType"
            filterable
            remote
            placeholder
            :remote-method="queryUseType"
            style="width:300px"
            v-bind:disabled="useDisabled"
          >
            <el-option
              v-for="useType in useTypeList"
              :key="useType.id"
              :label="useType.name"
              :value="useType.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序号" prop="sortNo">
          <el-input v-model="formModel.sortNo" placeholder="请输入排序号" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="所属园区/楼栋" prop="parentId">
          <el-select
            v-model="formModel.parentId"
            filterable
            remote
            placeholder
            :remote-method="queryRoomInfo"
            style="width:300px"
            :disabled="true"
          >
            <el-option
              v-for="roomInfo in roomInfoListFilter"
              :key="roomInfo.id"
              :label="roomInfo.name"
              :value="roomInfo.id"
            ></el-option>
          </el-select>
        </el-form-item> 
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import roomInfoApi from "@/api/base/roomInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";

export default {
  props: ["businessKey", "title","parentId"],
  data() {
    var validateUseType = (rule, value, callback) => {
        if(this.formModel.type == 3 && value==='') {
          callback(new Error('用电类型不能为空!'));
        } else {
          callback();
        }
    };

    return {
      ruleValidate: {
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        type: [
          {
            required: true,
            message: "类型:园区/楼栋/房号不能为空",
            trigger: "change"
          }
        ],
        useType: [
          { validator: validateUseType, trigger: "blur" }
        ],
        sortNo: [{ required: true, message: "排序号不能为空", trigger: "blur" }]
      },
      roomInfoList: [],
      useTypeList: [],
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {},
      parentDisabled: true,
      useDisabled: true,
      parentType: ""
    };
  },
  computed: {
    roomInfoListFilter() {
      var self = this;

      var list = [];

      var roomMap = {};

      self.roomInfoList.forEach((room)=>{
        roomMap[room.id] = room;
      });

      for(var key in roomMap){
        list.push(roomMap[key]);
      }

      return list;
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;
      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return roomInfoApi.add(self.formModel);
            } else {
              return roomInfoApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", {
                result : true,
                data : jsonData.data
              });
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close", {
                result : false
              });
            }
          });
        }
      });
    },
    queryRoomInfo(keywords) {
      var formData = new FormData();
      
      formData.append("keywords", keywords);
      formData.append("parentType", this.parentType);
      //formData.append("excludeId", this.formModel.id);
      formData.append("limit", 10);

      return roomInfoApi.query(formData).then(response => {
        var jsonData = response.data;

        if (jsonData.result) {
          this.roomInfoList = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    queryUseType(keywords) {
      var formData = new FormData();
      formData.append("parentId", keywords);
      //formData.append("excludeId", this.formModel.id);
      formData.append("limit", 10);

      return dataDictionaryApi.queryChildren(formData).then(response => {
        var jsonData = response.data;

        if (jsonData.result) {
          this.useTypeList = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    selectType(keywords) {
      this.parentType = keywords;
      this.queryRoomInfo("");
      if (keywords == "1") {
        this.parentDisabled = true;
      } else {
        this.parentDisabled = false;
        if (keywords == "3") {
          this.useDisabled = false;
        } else {
          this.useDisabled = true;
        }
      }
    }
  },
  async mounted() {
    var self = this;
    self.loading = true;
    
    await this.queryUseType("d18b7e03-63ed-4d86-b2b3-b93a7de2673b");
    await this.queryRoomInfo("");

    (function() {
      if (self.businessKey.length == 0) {
        return roomInfoApi.create(self.parentId);
      } else {
        return roomInfoApi.edit(self.businessKey);
      }
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;
        
        if (jsonData.result) {
          self.formModel = jsonData.data;

          if (self.formModel.parentId !== "") {
            this.parentType = self.formModel.type;
            this.parentDisabled = false;

            self.roomInfoList.push({
              id: self.formModel.parentId,
              name: self.formModel.parentName
            });
          }

          if (self.formModel.type == "3") {
            this.useDisabled = false;
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  }
};
</script>